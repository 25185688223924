import { Link } from "react-router-dom"
import residencialImage from "../assests/residencial.jpeg"
import commercialImage from "../assests/commercial.jpeg"
import React, { useRef } from 'react';



import floorPlanIcon from "../assests/blueprint.svg"
import matterportIcon from "../assests/virtual-reality.svg"
import cadFileIcon from "../assests/cad.svg"
import droneIcon from "../assests/drone.svg"
import photgraphyIcon from "../assests/photography.svg"
import flyerIcon from "../assests/brochure.svg"


import callIcon from '../assests/call.svg'
import emailIcon from '../assests/email.svg'
import calendarIcon from '../assests/calendar.svg'
import facebookIcon from '../assests/facebookColored.svg'
import instagramIcon from '../assests/instagramColored.svg'
import tiktokIcon from '../assests/tiktok.svg'


import { HashLink } from 'react-router-hash-link';


import {useNavigate} from 'react-router-dom'

import emailjs from '@emailjs/browser';

function Home(){
    const form = useRef();

    const navigate = useNavigate()

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_x565qif', 'template_330sd8o', form.current, '1e8fF1oIp_CUaGuX4')
        .then((result) => {
            console.log(result.text);
            navigate('/thankyou')
        }, (error) => {
            console.log(error.text);
        });

        
    };
    return(
        <div>
            <div className="hero-section">
                <div className="main">
                    <h2>Create your listing and showcase your space like never before</h2>
                    <h5>Let our blueprints and designs be the foundation of your dreams, shaping spaces with ingenuity</h5>
                    <HashLink smooth to="/#services">Services</HashLink>
                </div>
            </div>
            <div id="property-types" className="suited-for">
                <div className="suited-for-list">
                    <div className="residencial">
                        <img src={residencialImage} width={"150px"} alt="residencial property"/>
                        <div className="residencial-details">
                            <h3>Residencial for</h3>
                            <ul>
                                <li>Real Estate Agents</li>
                                <li>Home Builders</li>
                                <li>Contractors</li>
                                <li>Home Owners</li>
                                <li>Appartment Owners</li>
                                <li>Property Managers</li>
                            </ul>
                        </div>    
                    </div>
                    <div className="commercial">
                        <div className="commercial-details">
                            <h3>Commercial for</h3>
                            <ul>
                                <li>Commercial Agents</li>
                                <li>Office Space</li>
                                <li>Retail Businesses</li>
                                <li>Mixed-Use Development</li>
                                <li>Hospitality and lodging</li>
                                <li>Special Purpose Properties</li>
                            </ul>
                        </div>
                        <img src={commercialImage} width={"150px"} alt="commercial property"/>
                    </div>
                </div>
                <div  id="services" className="services">
                    <h2>List of Services</h2>
                    <div className="service-list">
                        <div className="service-item">
                            <img src={floorPlanIcon} alt=""/>
                            <h3>2D Flooring</h3>
                        </div>
                        <div className="service-item">
                            <img src={matterportIcon} alt=""/>
                            <h3>Matterport 3D</h3>
                        </div>
                        <div className="service-item">
                            <img src={cadFileIcon} alt=""/>
                            <h3>CAD File</h3>
                        </div>
                        <div className="service-item">
                            <img src={droneIcon} alt=""/>
                            <h3>Drone Photos & Videos</h3>
                        </div>
                        <div className="service-item">
                            <img src={photgraphyIcon} alt=""/>
                            <h3>Photography</h3>
                        </div>
                        <div className="service-item">
                            <img src={flyerIcon} alt=""/>
                            <h3>Flyer/Brochure for Real Estate</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div id="contact-info" className="contact-me">
                <h1>Contact me</h1>
                <div className="contact-container">
                    <div className="contact-me-prompt">
                        <form  ref={form} onSubmit={sendEmail}>
                            <p>Feel free to leave a message</p>
                            <div className="contact-name">
                                <input name="firstname" placeholder="First Name"></input>
                                <input name="lastname" placeholder="Last Name"></input>
                            </div>
                            <input name="email" placeholder="Email"></input>
                            <input name="phone" placeholder="Phone"></input>
                            <input name="message" placeholder="Message"></input>
                            <button type="submit" className="contact-button">Send</button>
                        </form>
                    </div>
                    <div className="contact-info">
                        <p className="contact-info-title">Info</p>
                        <div className="contact-info-items">
                            <img src={emailIcon} alt="Email"/>
                            <p>Shakhzoda@msmeasurements.com</p>
                        </div>
                        <div className="contact-info-items">
                            <img src={callIcon} alt="phone number"/>
                            <p>612-842-4187</p>
                        </div>
                        <div className="contact-info-items">
                            <img src={calendarIcon} alt="Calendar"/>
                            <p>Monday - Friday&nbsp;</p>
                            <p>&nbsp;9AM - 6PM</p>
                        </div>
                        <div className="follow-us">
                            <h2>
                                Social Media
                            </h2>
                            <div className="social-icons">
                                <Link to="https://www.facebook.com/msmeasurements?mibextid=LQQJ4d">
                                    <img src={facebookIcon} width={"40px"} alt="facebook"/>
                                </Link>
                                <Link to="https://instagram.com/msmeasurements?igshid=MmIzYWVlNDQ5Yg==">
                                    <img src={instagramIcon} width={"40px"} alt="instagram"/>
                                </Link>
                                <Link to="https://www.tiktok.com/@ms.measurements?_t=8f0DAyGb7Nb&_r=1">
                                    <img src={tiktokIcon} width={"40px"} alt="tiktok"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Home
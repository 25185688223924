import {Link} from "react-router-dom"
import { HashLink } from 'react-router-hash-link';
import logo from "../assests/logoSizes/logo.png"
import openIcon from "../assests/hamburger.svg"
import closeIcon from "../assests/close.svg"
import { useState } from "react";

function Header(){
    const [open,setOpen] = useState(true)

    const handleOnClick = () =>{
        setOpen(!open)
        if(document.querySelector(".nav-list").style.display === "block")
            document.querySelector(".nav-list").style.display="none"
        else{
            document.querySelector(".nav-list").style.display="block"
        }
    }


    return(
        <div>          
            <nav className="desktop-view">
                <div className="leftHeader">
                    <HashLink smooth to="/#services">Services</HashLink>
                    <HashLink smooth to="/#property-types">Property Type</HashLink>
                </div>
                    <Link className="desktop-view-logo" to="/">
                        <img src={logo} alt="logo"/>
                    </Link>
                <div className="rightHeader">
                    <Link to="/pricing/residencial">Pricing</Link>
                    <HashLink smooth to="/#contact-info">Contact</HashLink>
                </div>
            </nav>
            <div className="mobile-view">
                    <Link className="mobile-view-logo" to="/">
                        <img src={logo} alt="logo"/>
                    </Link>
                    <img className="nav-toggle" onClick={handleOnClick} src={open? openIcon:closeIcon} alt="toggle button"/>
                    <div className="nav-list">
                        <HashLink smooth to="/#services">Services</HashLink>
                        <HashLink smooth to="/#property-types">Property Type</HashLink>
                        <Link to="/pricing/residencial">Pricing</Link>
                        <HashLink smooth to="/#contact-info">Contact</HashLink>
                    </div>
            </div>
        </div>
    )
}


export default Header
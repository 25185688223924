import { useState } from "react"
import {Routes, Route} from "react-router-dom"
import Residential from "./PricingResidencial"
import Commercial from "./PricingCommercial"
import { Link,useLocation } from "react-router-dom"
import { useEffect } from "react"

function Pricing(){

    const location = useLocation ()

    useEffect(() => {
        console.log("hit")
        if(location.pathname ==="/pricing/commercial"){
            setResidential('in-active')
            setCommercial('active')
        }else if(location.pathname ==="residencial"){
            setResidential('active')
            setCommercial('in-active')
        }
    },[location.pathname]);

    const [residential,setResidential] = useState('active')
    const [commercial, setCommercial] = useState('in-active')

    const handleOnClick = (e) =>{
        if(e.target.id ==="commercial"){
            setResidential('in-active')
            setCommercial('active')
        }else if(e.target.id ==="residencial"){
            setResidential('active')
            setCommercial('in-active')
        }
    }

    return(
        <div className="pricing">
            <h2>Pricing Options</h2>
            <div className="switch-button">
                <Link id="residencial" onClick={handleOnClick} className={`residential-pricing-button ${residential}`} to="/pricing/residencial">Residencial</Link>
                <Link id="commercial" onClick={handleOnClick} className={`commercial-pricing-button ${commercial}`} to="/pricing/commercial">Commercial</Link>
            </div>
            <Routes>
                <Route path="residencial" element={<Residential />} />
                <Route path="commercial" element={<Commercial />} />
            </Routes>
            <p>Please note that there is a travel fee for properties greater than 30 miles outside Minneapolis.</p>
            <p>$1 per mile from 31-50 mile distance</p>
            <p>$2 per mile from 50+ mile distance</p>
        </div>
    )
}


export default Pricing


import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

function CommercialPricing(props){

    const form = useRef();

    let navigate = useNavigate() 

    const sendEmail = (e) => {
      e.preventDefault();
  

      emailjs.sendForm('service_x565qif', 'template_66xppwy', form.current, '1e8fF1oIp_CUaGuX4')
        .then((result) => {
            console.log(result.text);
            navigate('/thankyou')
        }, (error) => {
            console.log(error.text);
        });


    };
    return(
        <div className="commercial-pricing-container">
            <h2>Commercial Pricing</h2>
            <div className="commercial-pricing">
                <div className="pricing-hook">
                    <h3>Let's have a quick 15 minute call to...</h3>
                    <ul>
                        <li>Better understand your needs</li>
                        <li>Understand project requirements</li>
                        <li>Give you better customized pricing</li>
                    </ul>
                    <p>You can reach me at 612-842-4187</p>
                </div>
                 <form  ref={form} onSubmit={sendEmail} className="responsive-form">
                    <input placeholder="Name" type="text" name="name" />

                    <input placeholder="Email" type="email" name="email" />
                     <input placeholder="Phone" type="phone" name="phone" />
                    <input placeholder="Company" type="text" name="company" />
                    <textarea placeholder="Details" name="message"></textarea>
                    <input id="commercial-submit-button" type="submit" value="Submit" />
                </form>
            </div>
        </div>
    )
}


export default CommercialPricing
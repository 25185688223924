import {Link} from "react-router-dom"
import footerlogo from '../assests/logoSizes/bizcard.png'
import { HashLink } from 'react-router-hash-link';


function Footer(){
    
    return(
        <div className="footer">
            <img className="footerLogo" src={footerlogo} alt="logo with contact information"/>
            <nav className='footerNav'>
                <HashLink smooth to="/#services">Services</HashLink>
                <HashLink smooth to="/#property-types">Property Type</HashLink>
                <Link to="/pricing/residencial">Pricing</Link>
                <HashLink smooth to="/#contact-info">Contact</HashLink>
            </nav>
            <p>Copyright © 2023 - msmeasurements.com • All Rights Reserved</p>
        </div>
    )
}


export default Footer
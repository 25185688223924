import { useNavigate } from 'react-router-dom';
function ThankYouComponent(props) {
    let navigate = useNavigate() 
    return (
        <div className="thank-you-container">
            <h1>Thank You!</h1>
            <p>Your message have been received, I will contact you as soon as I can.</p>
            <button className="thank-you-button" onClick={() => navigate('/')}>back home</button>
        </div>
    );
}

export default ThankYouComponent;

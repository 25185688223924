import houseIcon from "../../assests/house.svg";
import virtualRealtyIcon from "../../assests/vr-glasses.svg"
import droneIcon from '../../assests/dronePricing.svg'
import floorPlanIcon from '../../assests/plan.svg'
import flyerIcon from '../../assests/brochurePricing.svg'
import cadIcon from '../../assests/cadPricing.svg'
import { HashLink } from 'react-router-hash-link';


function ResidencialPricing(){
    return(
        <div className="residencialPricing">
            <div className="card">
                <img src={houseIcon} alt="real-estate icon" />
                <p>Real Estate Photo</p>
                <ul>
                    <li>(0-1000 sq-ft) $165</li>
                    <li>(1000-2000 sq-ft) $190</li>
                    <li>(2000-3000 sq-ft) $210</li>
                    <li>(3000-4000 sq-ft) $240</li>
                    <li>(4000-5000 sq-ft) $250</li>
                    <li>(5000-6000 sq-ft) $320</li>
                    <li>(6000-7000 sq-ft) $360</li>
                    <li>(7000 sq-ft+)</li>
                    <HashLink smooth to="/#contact-info" alt="let's talk">
                        <div className="residencial-button">Let's talk</div>
                    </HashLink>
                </ul>
            </div>
            <div className="card">
                <img src={virtualRealtyIcon} alt="vertual reality" />
                <p>Matterport 3D Tour</p>
                <ul>
                    <li>(0-1000 sq-ft) $175</li>
                    <li>(1000-2000 sq-ft) $200</li>
                    <li>(2000-3000 sq-ft) $220</li>
                    <li>(3000-4000 sq-ft) $250</li>
                    <li>(4000-5000 sq-ft) $280</li>
                    <li>(5000-6000 sq-ft) $320</li>
                    <li>(6000-7000 sq-ft) $360</li>
                    <li>(7000 sq-ft+)</li>
                    <HashLink smooth to="/#contact-info" alt="let's talk">
                        <div className="residencial-button">Let's talk</div>
                    </HashLink>
                </ul>
            </div>
            <div className="card">
                <img src={droneIcon} alt="drone" />
                <p>Drone Photos & Videos</p>
                <ul>
                    <li>5 aerial images - $175</li>
                    <li>60 second video + 3-5 aerial images - $200</li>
                    <li>Need more?</li>
                    <HashLink smooth to="/#contact-info" alt="let's talk">
                        <div className="residencial-button">Let's talk</div>
                    </HashLink>
                </ul>
            </div>
            <div className="card">
                <img src={floorPlanIcon} alt="floorplan" />
                <p>2D Floor Plan</p>
                <ul>
                    <li>2D PDF Floor Plan with msmeasurements - 20c per sqft</li>
                </ul>
            </div>
            <div className="card">
                <img src={flyerIcon} alt="flyer" />
                <p>Flyer/Brochure</p>
                <ul>
                    <li>One Sided Flyer $75 JPEG</li>
                    <li>Counting sq ft usable and rentable,create marketing template with PDF 100$</li>
                </ul>
            </div>
            <div className="card">
                <img src={cadIcon} alt="real estate icon" />
                <p>CAD File</p>
                <ul>
                    <li>2D Floor plan with msmeasurements - 25c per sqft</li>
                    <li>Counting sq ft usable and rentable,create marketing template with PDF 100$</li>
                </ul>
            </div>
        </div>
    )
}


export default ResidencialPricing
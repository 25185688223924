import './App.css';
import Header from "./components/Header";
import {Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import Pricing from "./components/pricing/Pricing"
import Footer from './components/Footer';
import Thankyou from './components/Thankyou';
// import Contact from "./components/Contact"




function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/pricing/*" element={<Pricing/>}/>
        <Route path="/thankyou" element={<Thankyou/>}/>
        {/* <Route path="/contact" element={<Contact/>}/> */}
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
